import {ReactNode} from "react";

export const data: { title: string, icon: ReactNode }[] = [
    {
        title: 'Долгосрочные отношения',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'shake'}
                   src={require('./images/Handshake.png')}/>,
    },
    {
        title: ' Хранение автомобилей',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'box'}
                   src={require('./images/OpenBox.png')}/>,
    },
    {
        title: 'Качественный сервис',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'service'}
                   src={require('./images/Service.png')}/>,
    },
    {
        title: 'Ряд дополнительных услуг',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'tools'}
                   src={require('./images/AdministrativeTools.png')}/>,
    },
    {
        title: 'Кратчайшие сроки',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'timer'}
                   src={require('./images/Timer.png')}/>,
    },
    {
        title: 'Работаем с любыми типами креплений',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'rope'}
                   src={require('./images/Rope.png')}/>,
    },
]
