import React, {FC, ReactNode} from 'react'
import Header from "../Header";
import Contacts from "../../features/Contacts";
import Main from "../Main";
import backgroundMain from "../Layout/images/backgroundMain.png";
import backgroundCooperate from "../Layout/images/backgroudCooperate.png";
import backgroundBox from "../Layout/images/backgroundBox.png";

interface Props {
    children?: ReactNode
    backgroundKey?: 'MAIN' | "PARTS" | 'BOX'
}

const Layout: FC<Props> = ({children, backgroundKey = 'MAIN'}) => {

    const getBackground = () => {
        switch (backgroundKey) {
            case 'MAIN':
                return backgroundMain
            case "PARTS":
                return backgroundCooperate
            case "BOX":
                return backgroundBox
        }
    }

    const getTitle = () => {
        switch (backgroundKey) {
            case 'MAIN':
                return "Поиск, доставка,\nреализация"
            case "PARTS":
                return 'Автокомпоненты по \n' +
                    'доступной цене на \n' +
                    'лучших условиях'
            case 'BOX':
                return 'Услуги обработки контейнеров \n' +
                    'с транспортными средствами'
        }
    }

    return (
        <>
            <Header keyLink={backgroundKey !== 'MAIN' ? backgroundKey.toLowerCase() : undefined}/>
            <Main title={getTitle()} background={getBackground()}/>
            {children}
            <Contacts showPage={backgroundKey === 'MAIN'} isBox={backgroundKey === 'BOX'}/>
        </>
    )
}

export default Layout
