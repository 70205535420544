import {ReactNode} from "react";

export const data: { title: string, subtitle: string, icon: ReactNode }[] = [
    {
        title: 'Логопер',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'businessGroup'}
                   src={require('./images/businessGroup.png')}/>,
        subtitle: 'Партнерские отношения с одной из крупнейших логистических компаний России, которая является официальным партнером ОАО "РЖД".'
    },
    {
        title: 'Ракодо',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'truck'}
                   src={require('./images/truck.png')}/>,
        subtitle: 'Мы наладили сотрудничество с одной из крупных компаний в России, специализирующейся на разгрузке контейнеров. Это партнерство позволяет нам обеспечивать высококачественный сервис с минимизацией времени выполнения работ. '
    },
    {
        title: 'Logoper Asia',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'meetingRoom'}
                   src={require('./images/meetingRoom.png')}/>,
        subtitle: 'Эксклюзивные соглашения с азиатским представителем Логопер Азия.'
    },
    {
        title: 'Логистические операторы',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'plane'}
                   src={require('./images/plane.png')}/>,
        subtitle: 'Партнерские отношения с крупнейшими китайскими логистическими операторами.'
    },
    {
        title: 'Panda Express Line',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'boat'}
                   src={require('./images/boat.png')}/>,
        subtitle: 'Партнерские соглашения с морским логистическим провайдером Panda Express Line'
    },
    {
        title: 'Китайские заводы',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'factory'}
                   src={require('./images/factory.png')}/>,
        subtitle: 'Осуществляем прямые поставки товаров, исходящие непосредственно от ведущих производственных предприятий Китая.'
    },
]
