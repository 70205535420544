export const data: {key:string,value:string}[] = [
    {
        key: 'anchor-document',
        value:'Таможенный представитель LOGICON'
    },
    {
        key: 'anchor-gear',
        value: 'Действующие контейнерные терминалы\n' +
            'ТЛЦ Ховрино (Москва)\n' +
            'ТЛЦ Электроугли (Москва)\n' +
            'ТЛЦ Уральский (Екатеринбург) \n' +
            'ТЛЦ Сибирский (Новосибирск)'
    },
    {
        key: 'anchor-box',
        value: 'Контейнерный парк 70 000 TEU'
    },
    {
        key: 'anchor-construction',
        value: 'Контейнерные терминалы в стадии строительства\n' +
            'ТЛЦ в г. Артём\n' +
            'ТЛЦ в г. Хабаровск'
    },
    {
        key: 'anchor-water',
        value: "Флот 4 судна"
    },
    {
        key: 'anchor-tram',
        value: "Подвижной состав > 6 000 платформ"
    },
]
