import styled from "styled-components";

export const ContainerMap = styled.div`

  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`

export const WrapperMap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`

export const TooltipMap = styled.a`
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
`

export const MarkerMap = styled.div`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #E21111;
`
