import styled from "styled-components";

export const ContainerApp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 91px;
  padding: 91px 130px;
`

export const PageTitle = styled.span`
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.6px;
`

export const Title = styled.div`
  color: #FFF;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 78px; /* 130% */
`

export const Anchor = styled.div`
  position: absolute;
  margin-top: -170px;
`
