import {ReactNode} from "react";

export const data: { title: string, icon: ReactNode }[] = [
    {
        title: 'Доставка \n' +
            '«от двери до двери»',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'truck'}
                   src={require('./images/earth.png')}/>,
    },
    {
        title: 'Услуги страхования',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'easy'}
                   src={require('./images/list.png')}/>,
    },
    {
        title: 'Прогнозируемое\n' +
            'транзитное время доставки',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'search'}
                   src={require('./images/time.png')}/>,
    },
    {
        title: 'Мультимодальные \n' +
            'перевозки\n' +
            ' ',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'box'}
                   src={require('./images/globe.png')}/>,
    },
]

export const dataTooltip: { value: ReactNode, key: string }[] = [
    {
        value: <div>
            <span style={{fontWeight: 700}}>ТЛЦ Ховрино (Москва){'\n'}</span>
            Вместимость контейнерной площадки – 5 000 TEU{'\n'}
            Проектная мощность – 280 000 TEU/год{'\n'}
            <span style={{fontWeight: 700}}>ТЛЦ Электроугли  (Москва){'\n'}</span>
            Вместимость контейнерной площадки – 5 000 TEU{'\n'}
            Проектная мощность – 280 000 TEU/год{'\n'}
            <img alt={'img1'} src={require('./images/tooltipFirst.png')}/>
        </div>,
        key: 'first'
    },
    {
        value: <div>
            <span style={{fontWeight: 700}}>ТЛЦ Уральский{'\n'}</span>
            Площадь – более 131 га{'\n'}
            Проектная мощность – 600 000 TEU/год{'\n'}
            <img alt={'img1'} src={require('./images/tooltipSecond.png')}/>
        </div>,
        key: 'second'
    },
    {
        value: <div>
            <span style={{fontWeight: 700}}>ТЛЦ Сибирский{'\n'}</span>
            Площадь – более 338 га{'\n'}
            Перерабатывающая способность – 1 000 000 TEU/год{'\n'}
            <img alt={'img1'} src={require('./images/tooltipThird.png')}/>
        </div>,
        key: 'third'
    }
]
