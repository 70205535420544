import React, {FC} from 'react'
import {Anchor, ContainerApp, PageTitle, Title} from "../../../styled";
import {
    ContainerContacts,
    ContainerContent,
    Input,
    LeftSide,
    RightSide,
    Subtitle,
    Text, TextArea,
    TitleContacts,
    WaterText
} from "./styled";
import background from './images/background.png'
import {ContainerAboutUs} from "../AboutUs/styled";
import {useImageSize} from "react-image-size";
import {Button} from "../../shared/Main/styled";

interface Props {
    isBox?: boolean
    showPage?: boolean
    keyLink?:string;
}

const Contacts: FC<Props> = ({isBox = false, showPage = false}) => {
    const [dimensions] = useImageSize(background);

    return (
        <>
            <ContainerContacts style={{backgroundImage: `url(${background})`, minHeight: dimensions?.height}}>
                <ContainerApp>
                    <ContainerAboutUs>
                        <Anchor id={'contacts'}/>
                        <Title>Контакты</Title>
                        <ContainerContent>
                            <LeftSide style={{alignItems: 'flex-start', marginLeft: 200}}>
                                <TitleContacts>
                                    Офис
                                    {isBox && `\nНосовихинское шоссе, 26-й\nкилометр, 1с1, Электроугли,\nБогородский городской округ,\nМосковская область\n`}
                                </TitleContacts>
                                <Text>
                                    {'ООО «ПАНДА ТРЭЙД», Москва\n' +
                                        'Иван Афонин\n' +
                                        'E-mail: ivan.pandatrade@gmail.com\n' +
                                        '+ 79777971549\n' +
                                        'Валентин Кахидзе\n' +
                                        'E-mail: valentin.pandatrade@gmail.com\n' +
                                        '+79672080228'}
                                </Text>
                            </LeftSide>
                            <RightSide>
                                <Subtitle>
                                    НАПИСАТЬ НАМ
                                </Subtitle>
                                <Input placeholder={'Имя*'}/>
                                <Input placeholder={'Email*'}/>
                                <Input placeholder={'Телефон*'}/>
                                <TextArea placeholder={'Комментарий'}/>
                                <Button to={'#contacts'} smooth={true} style={{marginTop: 13}}>
                                    СОТРУДНИЧАТЬ С НАМИ
                                </Button>
                            </RightSide>
                        </ContainerContent>
                    </ContainerAboutUs>
                </ContainerApp>
            </ContainerContacts>
            <div style={{width: '100%', textAlign: 'center', paddingBlock: 8}}>
                <WaterText>
                    ООО “ПАНДА ТРЭЙД” © 2023
                </WaterText>
            </div>
        </>

    )
}

export default Contacts
