import styled from "styled-components";

export const Subtitle = styled.div`
  color: #FFF;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 8px;
`

export const ContainerList = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  
`

export const ListItem  = styled.div`
  display: flex;
  gap: 40px;
  width: 35%;
  margin-bottom: 64px;
`

export const TextItem = styled.div`
  width: 370px;
`
