import React, {FC} from 'react'
import {Title} from "../../../styled";
import {ContainerAboutUs} from "../AboutUs/styled";
import {ContainerList, ListItem, Subtitle, TextItem} from "../Partners/styled";
import {data} from "./data";


const DeliveryProcessing: FC = () => {

    return (
        <ContainerAboutUs>
            <Title>Транспортная обработка грузов</Title>
            <ContainerList>
                {data.map((itemMap, index) => (
                    <ListItem style={index % 2 === 1 ? {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    } : {alignItems: 'center'}} key={index}>
                        {itemMap.icon}
                        <TextItem>
                            <Subtitle>
                                {itemMap.title}
                            </Subtitle>
                        </TextItem>
                    </ListItem>
                ))}
            </ContainerList>
        </ContainerAboutUs>
    )
}

export default DeliveryProcessing
