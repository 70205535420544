import React, {FC} from 'react'
import {ContainerApp} from "../../styled";
import AboutUs from "../../components/features/AboutUs";
import Partners from "../../components/features/Partners";
import Activity from "../../components/features/Activity";
import TypeDelivery from "../../components/features/TypeDelivery";
import Geography from "../../components/features/Geography";
import Layout from "../../components/shared/Layout";

const Main: FC = () => {

    return (
        <Layout>
            <ContainerApp>
                <AboutUs/>
                <Partners/>
                <Activity/>
                <TypeDelivery/>
                <Geography/>
            </ContainerApp>
        </Layout>
    )
}

export default Main
