import React, {FC} from 'react'

import {Button, ContentContainer, StyledContainerMain, Subtitle, Title} from "./styled";

interface Props {
    title: string;
    background: string;
}

const Main: FC<Props> = ({background, title}) => {

    return (
        <StyledContainerMain id={'home'} url={background} height={window.innerWidth / 2}>
            <ContentContainer>
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    Торговый дом
                </Subtitle>
                <Button to={'#contacts'} smooth={true}>
                    СОТРУДНИЧАТЬ С НАМИ
                </Button>
            </ContentContainer>
        </StyledContainerMain>
    )
}

export default Main
