import React, {FC} from 'react'
import {ContainerAboutUs, Text} from "../AboutUs/styled";
import {Anchor, PageTitle, Title} from "../../../styled";
import {ContainerList, ListItem, Subtitle, TextItem} from "./styled";
import {data} from "./data";

interface Props {
    props?: any
}

const Partners: FC<Props> = ({props}) => {

    return (
        <ContainerAboutUs>
            <Anchor id={'partners'}/>
            <Title>Наши партнеры</Title>
            <ContainerList>
                {data.map((itemMap, index) => (
                    <ListItem style={index % 2 === 1 ? {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    } : undefined} key={index}>
                        <div style={{display: 'flex', height: '100%', alignItems: 'center'}}>
                            {itemMap.icon}
                        </div>
                        <TextItem>
                            <Subtitle>
                                {itemMap.title}
                            </Subtitle>
                            <Text>
                                {itemMap.subtitle}
                            </Text>
                        </TextItem>
                    </ListItem>
                ))}
            </ContainerList>
        </ContainerAboutUs>
    )
}

export default Partners
