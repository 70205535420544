import React, {FC} from 'react'
import {ButtonList, ContainerAboutUs, ContentAboutUs, Text, TextTooltip} from "./styled";
import {Anchor, PageTitle, Title} from "../../../styled";
import background from './images/list.png'
import {useImageSize} from "react-image-size";
import {Tooltip} from "react-tooltip";
import {data} from "../../shared/Main/data";

interface Props {
    isPart?: boolean
}

const AboutUs: FC<Props> = ({isPart = false}) => {
    const [dimensions] = useImageSize(background);

    return (
        <ContainerAboutUs>
            <Anchor id={'about-us'}/>
            <ContentAboutUs>
                <div>
                    <Title>О нас</Title>
                    {isPart ?
                        <Text>
                            Торговый дом «Панда трейд» является одним из крупнейших оптовых поставщиков автозапчастей в
                            России с прямыми поставками от заводов-производителей.Огромный выбор оригинальных авто
                            комплектующих компонентов ведущих китайских марок: Chery, Omoda, Exeed, Hongqui, Voyah, BYD,
                            JAC, Geely, Haval, Great wall, Avatar и др.
                            {`\n`}
                            Также предоставляем широкий ассортимент качественных аналоговых запчастей и комплектующих
                            для Volkswagen, Toyota, Ford и Mazda и др.
                            {`\n\n`}
                            Мы имеем партнерские отношения с крупнейшими китайскими автопроизводителями, что позволяет
                            нам предложить лучший сервис по самым выгодным ценам на рынке автозапчастей в России. Наша
                            цель- максильно освободить клиента от забот, чтобы вы могли получить груз в точке
                            назначения, затратив на это минимум времени и сил.
                        </Text>
                        :
                        <Text>
                            Торговый дом «Панда Трейд» – мы являемся одним крупнейших оптовых поставщиков китайских
                            товаров
                            в Россию. Обеспечиваем оптимальное соотношение стоимости и качества товара. Наша компания
                            приглашает к сотрудничеству фирмы оптовиков, интернет-магазины, автосервисы и индивидуальных
                            предпринимателей, предлагая купить товары по доступной цене как со склада в Москве, так и
                            под
                            заказ с самыми выгодными условиями.
                            {`\n\n`}
                            Мы являемся подразделением КЛК Холдинга - компании с более чем 15-летним опытом организации
                            мультимодальных контейнерных перевозок как во внутрироссийском, так и международном
                            сообщении.
                        </Text>
                    }
                </div>
                {isPart
                    ?
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img style={{height: 416}} alt={'img'} src={require('./images/RightImage.png')}/>
                    </div>
                    : <div
                        style={
                            {
                                width: dimensions?.width,
                                height: dimensions?.height,
                                backgroundImage: `url(${background})`,
                                backgroundRepeat: 'no-repeat',

                            }
                        }
                    >
                        {data.map((itemMap) => (
                            <Tooltip
                                style={{backgroundColor: '#FFFFFF', opacity: 1, borderRadius: 12, zIndex: 1111}}
                                key={itemMap.key}
                                closeEvents={{click: true}}
                                openEvents={{click: true}}
                                anchorSelect={`.${itemMap.key}`} place="top">
                                <TextTooltip>
                                    {itemMap.value}
                                </TextTooltip>
                            </Tooltip>
                        ))}
                        <ButtonList className="anchor-construction" style={{marginTop: 385, marginLeft: 70}}>
                            <img alt={'Construction'} src={require('./images/Construction.png')}/>
                        </ButtonList>
                        <ButtonList className="anchor-box" style={{marginTop: 385, marginLeft: 475}}>
                            <img alt={'Box'} src={require('./images/Box.png')}/>
                        </ButtonList>
                        <ButtonList className="anchor-gear" style={{marginTop: 170, marginLeft: 75}}>
                            <img alt={'Gear'} src={require('./images/Gear.png')}/>
                        </ButtonList>
                        <ButtonList className="anchor-document" style={{marginTop: 485, marginLeft: 280}}>
                            <img alt={'Document'} src={require('./images/Document.png')}/>
                        </ButtonList>
                        <ButtonList className="anchor-water" style={{marginTop: 55, marginLeft: 280}}>
                            <img alt={'Water'} src={require('./images/Water.png')}/>
                        </ButtonList>
                        <ButtonList className="anchor-tram" style={{marginTop: 165, marginLeft: 475}}>
                            <img alt={'Tram'} src={require('./images/Tram.png')}/>
                        </ButtonList>
                    </div>}
            </ContentAboutUs>
        </ContainerAboutUs>
    )
}

export default AboutUs
