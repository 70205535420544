import React, {FC} from 'react'
import Layout from "../../components/shared/Layout";
import AboutUs from "../../components/features/AboutUs";
import {ContainerApp} from "../../styled";
import Marquee from "../../components/shared/Marquee";
import {dataImg} from "./data";
import Activity from "../../components/features/Activity";

const Parts: FC = () => {

    return (
        <Layout backgroundKey={'PARTS'}>
            <ContainerApp>
                <AboutUs isPart={true}/>
            </ContainerApp>
            <Marquee items={dataImg}/>
            <ContainerApp>
                <Activity isPart={true}/>
            </ContainerApp>
            <div>
                <img style={{width: '100%', marginBottom: -5, marginTop: 30}} alt={'ship'}
                     src={require('./images/ship.png')}/>
            </div>
        </Layout>
    )
}

export default Parts
