import styled from "styled-components";

export const TitleDelivery = styled.div`
  color: #FFF;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 78px; /* 195% */
`

export const TextDelivery = styled.div`
  color: #FFF;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.571px; /* 185.714% */
  margin-top: 21px;
`

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:11px;
  width: 100%;
  margin-top: 22px;
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`

export const ListItemTitle = styled.span`
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.571px; /* 157.857% */
  white-space: pre-wrap;
  text-align: center;
`
