import React, {FC} from 'react'
import {ContainerAboutUs} from "../AboutUs/styled";
import {Anchor, PageTitle, Title} from "../../../styled";
import {ListItem, ListItemTitle, StepperContainer, TextDelivery, TitleDelivery} from "./styled";
import {data} from "./data";

const TypeDelivery: FC = () => {

    return (<ContainerAboutUs>
        <Anchor id={'type-delivery'}/>
        <Title>Опережающие логистические решения</Title>
        <div style={{
            position: 'absolute',
            display: 'flex',
            marginTop: 150,
            height: 600,
            marginLeft: -128,
            width: '100%'
        }}>
            <img alt={'second'} width={'25%'} style={{objectFit: 'cover'}} src={require('./images/second.png')}/>
            <img alt={'third'} width={'25%'} style={{objectFit: 'cover'}} src={require('./images/third.png')}/>
            <img alt={'fourth'} width={'25%'} style={{objectFit: 'cover'}} src={require('./images/fourth.png')}/>
            <img alt={'first'} width={'25%'} style={{objectFit: 'cover'}} src={require('./images/first.png')}/>
        </div>
        <div style={{marginTop: 750}}>
            <TitleDelivery>
                Комплексное решение для клиентов
            </TitleDelivery>
            <TextDelivery>
                Свяжись с нами чтобы получить предложение.
            </TextDelivery>
            <StepperContainer>
                {data.map((itemMap, index) => (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between',width:'100%'}}
                         key={itemMap.title}>
                        <ListItem>
                            <ListItemTitle>
                                {itemMap.title}
                            </ListItemTitle>
                            {itemMap.icon}
                        </ListItem>
                        {index !== data.length - 1 && <img alt={'arrow'} src={require('./images/arrow.png')}/>}
                    </div>
                ))}
            </StepperContainer>
        </div>
    </ContainerAboutUs>)
}

export default TypeDelivery
