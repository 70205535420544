import {ContainerImageCarousel} from "./styled";

export const data = [
    {
        key: '1',
        value:
            <ContainerImageCarousel>
                <img
                    style={{height: 560}}
                    alt={'1'} src={require('./images/1.jpg')}/>
            </ContainerImageCarousel>
    },
    {
        key: '2',
        value:
            <ContainerImageCarousel>
                <img style={{height: 560}} alt={'2'} src={require('./images/2.jpg')}/>
            </ContainerImageCarousel>
    },
    {
        key: '3',
        value:
            <ContainerImageCarousel>
                <img
                    style={{height: 560}}
                    alt={'3'} src={require('./images/3.jpg')}/>
            </ContainerImageCarousel>
    },
    {
        key: '4',
        value:
            <ContainerImageCarousel>
                <img
                    style={{height: 560}}
                    alt={'4'} src={require('./images/4.jpg')}/>
            </ContainerImageCarousel>
    },
    {
        key: '5',
        value:
            <ContainerImageCarousel>
                <img
                    style={{height: 560}}
                    alt={'5'} src={require('./images/5.jpg')}/>
            </ContainerImageCarousel>
    },
    {
        key: '6',
        value:
            <ContainerImageCarousel>
                <img
                    style={{height: 560}}
                    alt={'6'} src={require('./images/6.jpg')}/>
            </ContainerImageCarousel>
    },
    {
        key: '7',
        value:
            <ContainerImageCarousel>
                <img
                    style={{height: 560}}
                    alt={'7'} src={require('./images/7.jpg')}/>
            </ContainerImageCarousel>
    },
    {
        key: '8',
        value:
            <ContainerImageCarousel>
                <img
                    style={{height: 560}}
                    alt={'8'} src={require('./images/8.jpg')}/>
            </ContainerImageCarousel>
    },
    {
        key: '9',
        value:
            <ContainerImageCarousel>
                <img
                    style={{height: 560}}
                    alt={'9'} src={require('./images/9.jpg')}/>
            </ContainerImageCarousel>
    },
]
