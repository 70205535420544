import React, {FC, ReactNode} from 'react'
import {ContainerList} from "./styled";

interface Props {
    items: ReactNode[]
}

const Marquee: FC<Props> = ({items}) => {

    return (
            <ContainerList>
                {items.map((itemMap, index) => (
                        <div key={index}>{itemMap}</div>
                    )
                )}
            </ContainerList>
    )
}

export default Marquee
