import React, {FC} from 'react'
import {
    ContainerHeader,
    ContainerImage,
    ContainerTitleLogo, CustomNavLink,
    LeftSideHeader,
    RightSideHeader, StyledLink,
    SubtitleLogo,
    TitleLogo
} from "./styled";
import {HashLink} from "react-router-hash-link";
import {Dropdown} from "antd";

interface Props {
    keyLink?: string
}

const items = [
    {
        key: 'parts',
        label: (
            <CustomNavLink to={'/parts#home'}>
                Запчасти
            </CustomNavLink>
        ),
    },
    {
        key: 'box',
        label: (
            <CustomNavLink to={'/box#home'}>
                {'Растарка/Затарка'}
            </CustomNavLink>
        ),
    },
]

const Header: FC<Props> = ({keyLink}) => {


    return (
        <ContainerHeader>
            <LeftSideHeader>
                <HashLink smooth={true} to={`/#home`}>
                    <ContainerImage alt={'logo'} src={require('./images/logo.png')}/>
                </HashLink>
                <ContainerTitleLogo>
                    <TitleLogo>ПАНДА ТРЭЙД</TitleLogo>
                    <SubtitleLogo>торговый дом</SubtitleLogo>
                </ContainerTitleLogo>
            </LeftSideHeader>
            <RightSideHeader>
                <StyledLink to={'/#about-us'} smooth={true}>О нас</StyledLink>
                <StyledLink to={'/#partners'} smooth={true}>Партнеры</StyledLink>
                <Dropdown menu={{items}}>
                    <StyledLink to={'/#activity'} smooth={true}>Деятельность</StyledLink>
                </Dropdown>
                <StyledLink to={'/#type-delivery'} smooth={true}>Виды перевозок</StyledLink>
                <StyledLink to={'/#geography'} smooth={true}>География</StyledLink>
                <StyledLink to={`/${keyLink ?? ''}#contacts`} smooth={true}>Контакты</StyledLink>
            </RightSideHeader>
        </ContainerHeader>
    )
}

export default Header
