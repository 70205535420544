import styled from "styled-components";

export const Card = styled.div`
  border-radius: 44px;
  border: 1px solid #FFF;
  background: #0C2B75;
  max-width: 297px;
  padding: 16px;
  width: 25%;
`

export const ContainerSecondLineCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap:16px;
`

export const TitleCard = styled.span`
  color: #FFF;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  white-space: nowrap;
  line-height: 78px; /* 130% */
`

export const SubtitleCard = styled.span`
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; /* 125% */
`
