import styled from "styled-components";

export const ContainerAboutUs = styled.div`
  display: flex;
  flex-direction: column;
  --rt-opacity: 1 !important;
`

export const Text = styled.div`
  color: rgba(255, 255, 255, 0.60);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  max-width: 460px;
  white-space: pre-wrap;
  line-height: 31.571px;
`

export const ContentAboutUs = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonList = styled.a`
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
`

export const TextTooltip = styled.div`
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 31.571px;
  white-space: pre-wrap;
`
