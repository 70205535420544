import React, {FC} from 'react'
import {ContainerAboutUs} from "../AboutUs/styled";
import {Title} from "../../../styled";
import {Text} from "../AboutUs/styled";
import {Card, ContainerSecondLineCard, SubtitleCard, TitleCard} from "./styled";

const AboutUsBox: FC = () => {

    return (
        <ContainerAboutUs style={{gap: 64}}>
            <Title>О нас</Title>
            <Text style={{maxWidth: '100%'}}>
                Мы предоставляем услуги по разгрузке и погрузке автомобилей в/из контейнеров любой степени сложности. В
                дополнение, наше предложение включает в себя возможность хранения и технического обслуживания
                транспортных средств, а также их доставку в любую точку Российской Федерации.
            </Text>
            <div style={{display: 'flex', flexDirection: 'row', gap: 32, justifyContent: 'center'}}>
                <Card>
                    <TitleCard>
                        1 000 000
                    </TitleCard>
                    <ContainerSecondLineCard>
                        <SubtitleCard>
                            {`перевезенных автомобилей`}
                        </SubtitleCard>
                        <img alt={'car'} src={require('./images/Car.png')}/>
                    </ContainerSecondLineCard>
                </Card>
                <Card>
                    <TitleCard>
                        {'15 >'}
                    </TitleCard>
                    <ContainerSecondLineCard>
                        <SubtitleCard>
                            {`партнеров`}
                        </SubtitleCard>
                        <img alt={'people'} src={require('./images/People.png')}/>
                    </ContainerSecondLineCard>
                </Card>
                <Card>
                    <TitleCard>
                        20
                    </TitleCard>
                    <ContainerSecondLineCard>
                        <SubtitleCard>
                            {`автомобилевозов`}
                        </SubtitleCard>
                        <img alt={'truck'} src={require('./images/Truck.png')}/>
                    </ContainerSecondLineCard>
                </Card>
                <Card>
                    <TitleCard>
                        10 000 +
                    </TitleCard>
                    <ContainerSecondLineCard>
                        <SubtitleCard>
                            {`парковочных\nмест`}
                        </SubtitleCard>
                        <img alt={'parking'} src={require('./images/Parking.png')}/>
                    </ContainerSecondLineCard>
                </Card>
            </div>
        </ContainerAboutUs>
    )
}

export default AboutUsBox
