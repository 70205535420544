import styled from "styled-components";

export const ContainerContacts = styled.div`
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: center;
`

export const WaterText = styled.span`
  color: rgba(255, 255, 255, 0.60);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.571px; /* 185.714% */
`

export const ContainerContent =styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
`

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  gap: 32px;
`

export const Text = styled.div`
  color: rgba(255, 255, 255, 0.60);
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.571px; /* 185.714% */
  white-space: pre-wrap;
`

export const TitleContacts =styled.span`
  color: rgba(255, 255, 255, 0.60);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
  white-space: pre-wrap;
`

export const RightSide = styled(LeftSide)`
  gap:20px
`

export const Subtitle = styled.div`
  color: #FFF;
  margin-bottom: 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 150% */
`

export const Input = styled.input`
  width: 100%;
  max-width: 485px;
  border-radius: 8px;
  border: none;
  padding: 8px;
  height: 32px;
  font-size: 17px;
  font-weight: 500;

  &::placeholder {
    color: #757575;
    font-family: 'Barlow', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`

export const TextArea = styled.textarea`
  width: 100%;
  max-width: 485px;
  height: 120px;
  border-radius: 8px;
  border: none;
  padding: 8px;
  font-size: 17px;
  font-weight: 500;

  &::placeholder {
    color: #757575;
    font-family: 'Barlow', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`
