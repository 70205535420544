import React, {FC} from 'react'
import Layout from "../../components/shared/Layout";
import {ContainerApp} from "../../styled";
import AboutUsBox from "../../components/features/AboutUsBox";
import DeliveryProcessing from "../../components/features/DeliveryProcessing";
import {Carousel} from "antd";
import {data} from "./data";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

const Box: FC = () => {

    return (
        <Layout backgroundKey={'BOX'}>
            <ContainerApp>
                <AboutUsBox/>
                <DeliveryProcessing/>
            </ContainerApp>
            <Carousel
                style={{height: 780}}
                arrows
                prevArrow={<LeftOutlined/>}
                nextArrow={<RightOutlined/>}
            >
                {data.map((itemMap) =>
                    <div key={itemMap.key}>
                        {itemMap.value}
                    </div>
                )}
            </Carousel>
        </Layout>
    )
}

export default Box
