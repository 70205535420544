import {ReactNode} from "react";

export const data: { title: string, subtitle: string, icon: ReactNode }[] = [
    {
        title: 'Доставка',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'truck'}
                   src={require('./images/truck.png')}/>,
        subtitle: 'Прогнозируемое транзитное время доставки. Услуга доставки осуществляется по принципу \'от двери до двери\'.'
    },
    {
        title: 'Реализация',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'easy'}
                   src={require('./images/easy.png')}/>,
        subtitle: 'Наша организация занимает одну из лидирующих позиций в сфере поставок товаров из Китая в Россию, осуществляя прямые поставки непосредственно от производителей. Мы стремимся к достижению цели предоставления товаров высокого качества, которые будут доступны каждому потребителю.'
    },
    {
        title: 'Поиск',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'search'}
                   src={require('./images/search.png')}/>,
        subtitle: 'Поиск автозапчастей крупшнейших китайских производителей напрямую с завода. Предоставляем выбор из оригинальных запчастей на китайские автомобили, а так же качественные копии на иномарки других стран.'
    },
    {
        title: 'Хранение',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'box'}
                   src={require('./images/holdingBox.png')}/>,
        subtitle: 'В рамках наших операционных возможностей, мы обладаем способностью хранения товаров любого объема без необходимости привлекать сторонние организации-посредники. Для этих целей у нас имеются складские помещения, расположенные на территории всей Российской Федерации.'
    },
]

export const dataPart: { title: string, subtitle: string, icon: ReactNode }[] = [
    {
        title: 'Поиск',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'search'}
                   src={require('./images/search.png')}/>,
        subtitle: 'Поиск автозапчастей крупшнейших китайских производителей напрямую с завода. Предоставляем выбор из оригинальных запчастей на китайские автомобили, а так же качественные копии на иномарки других стран.'
    },
    {
        title: 'Реализация',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'easy'}
                   src={require('./images/easy.png')}/>,
        subtitle: 'Мы имеем партнерские отношения с крупнейшими китайскими автопроизводителями, что позволяет нам предложить лучший сервис по самым выгодным ценам на рынке автозапчастей в России.'
    },
    {
        title: 'Доставка',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'truck'}
                   src={require('./images/truck.png')}/>,
        subtitle: 'Партнерство с одной из крупнейших логистических компаний России позволяет в кратчайшие сроки доставить необходимые Вам запчасти и компоненты напрямую от заводов-производителей в любую часть России.'
    },
    {
        title: 'Хранение',
        icon: <img style={{objectFit: 'contain', width: 66, height: 66}} alt={'box'}
                   src={require('./images/holdingBox.png')}/>,
        subtitle: ' Благодаря сотрудничеству с крупнейшими транспортно логистическими центрами, у нас в наличии всегда есть широкий ассортимент самых различных категорий товаров.'
    },
]
