import {ReactNode} from "react";

export const data: { title: string, icon: ReactNode }[] = [
    {
        title: 'Выкуп',
        icon: <img alt={'factory'} src={require('../Partners/images/factory.png')}/>
    },
    {
        title: 'Таможенное\nоформление',
        icon: <img alt={'police'} src={require('./images/police.png')}/>
    },
    {
        title: 'Железнодороженые\nперевозки',
        icon: <img alt={'tram'} src={require('../AboutUs/images/Tram.png')}/>
    },
    {
        title: 'Морские \n' +
            'перевозки',
        icon: <img alt={'boat'} src={require('./images/boat.png')}/>
    },
    {
        title: 'Доставка до \n' +
            'склада!',
        icon: <img alt={'truck'} src={require('../Partners/images/truck.png')}/>
    },
]
