import styled from "styled-components";
import {HashLink} from "react-router-hash-link";
import {Link} from "react-router-dom";

export const ContainerHeader = styled.div`
  padding: 23px 64px;
  box-sizing: border-box;
  z-index: 111;
  background-color: #FFFFFF;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const ContainerImage = styled.img`
  width: 93px;
  height: 88px;
`

export const LeftSideHeader = styled.div`
  display: flex;
  gap:34px;
  align-items: center;
`

export const TitleLogo = styled.span`
  color: #000;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
`

export const SubtitleLogo = styled.span`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  opacity: 0.5;
`

export const ContainerTitleLogo = styled.div`
  display: flex;
  flex-direction: column;
  gap:8px;
`

export const RightSideHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 36px;
`

export const StyledLink = styled(HashLink)`
  color: #000;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s color;
  text-decoration: none;

  &:hover {
    color: #2E64FE;
  }

`

export const CustomNavLink = styled(HashLink)`
  font-size: 16px;
  font-weight: 500;
`
