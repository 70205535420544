import styled from "styled-components";
import {HashLink} from "react-router-hash-link";


export const StyledContainerMain = styled.div<{url:string,height:number}>`
  height: ${({height}) => height}px;
  padding-inline: 207px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: ${({url}) => `url(${url})`};
  display: flex;
  align-items: center;
`

export const Title = styled.span`
  color: #FFF;
  font-size: 55px;
  font-weight: 700;
  white-space: pre-wrap;
  line-height: 75px;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Subtitle = styled.span`
  color: rgba(255, 255, 255, 0.60);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.571px; /* 157.857% */
  margin-top: 26px;
  margin-bottom: 12px;
`

export const Button = styled(HashLink)`
  text-decoration: none;
  display: flex;
  padding: 4px 16px;
  height: 32px;
  width: 252px;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: #2F54EB;
  color: var(--neutral-1, #FFF);
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: #2E64FE;
  }

`
