import {createBrowserRouter} from "react-router-dom";
import Main from "./pages/Main";
import Parts from "./pages/Parts";
import Box from "./pages/Box";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Main/>,
    },
    {
        path: "/parts",
        element: <Parts/>,
    },
    {
        path: "/box",
        element: <Box/>,
    },
]);
