import styled from "styled-components";
import Marquee from "react-fast-marquee";

export const ContainerList = styled(Marquee)`
  display: flex;
  gap: 10px;
  height: 112px;
  background: white;
  width: 100%;
  overflow: hidden;
`
