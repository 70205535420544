import styled from "styled-components";
import {HashLink} from "react-router-hash-link";

export const ImageBackground = styled.div`
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 22px;
  padding-block: 75px;
`

export const TitleActivity = styled.span`
  color: #FFF;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  white-space: pre-wrap;
`

export const ContainerActivity = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

export const Button = styled(HashLink)`
  background: #000;
  width: 235.99px;
  padding-block: 14px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
