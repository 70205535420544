import React, {FC} from 'react'
import {ContainerAboutUs, Text} from "../AboutUs/styled";
import {Anchor, PageTitle, Title} from "../../../styled";
import {ContainerList, ListItem, Subtitle, TextItem} from "../Partners/styled";
import {data, dataPart} from "./data";
import {Button, ContainerActivity, ImageBackground, TitleActivity} from "./styled";
import backgroundGaika from './images/gaika.png'
import backgroundBox from './images/box.png'
import {useImageSize} from "react-image-size";
import background from "../../shared/Layout/images/backgroundMain.png";
import {useNavigate} from "react-router-dom";

interface Props {
    isPart?: boolean;
}

const Activity: FC<Props> = ({isPart = false}) => {
    const [dimensions] = useImageSize(background);
    const navigate = useNavigate();

    return (
        <ContainerAboutUs>
            <Anchor id={'activity'}/>
            <Title>{isPart ? 'Наши преимущества' : 'Наши виды деятельности'}</Title>
            <ContainerList>
                {(isPart ? dataPart : data).map((itemMap, index) => (
                    <ListItem
                        style={index % 2 === 1 ? {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end'
                        } : {marginRight:'5%'}} key={index}>
                        <div style={{display: 'flex', height: '100%', alignItems: 'center'}}>
                            {itemMap.icon}
                        </div>
                        <TextItem style={isPart ? {width: 500} : undefined}>
                            <Subtitle>
                                {itemMap.title}
                            </Subtitle>
                            <Text>
                                {itemMap.subtitle}
                            </Text>
                        </TextItem>
                    </ListItem>
                ))}
            </ContainerList>
            {!isPart && <div style={{display: 'flex', width: "100%", height: dimensions?.height, marginTop: 75}}>
                <ImageBackground style={{backgroundImage: `url(${backgroundGaika})`}}>
                    <ContainerActivity>
                        <img alt={'tools'} src={require('./images/tools.png')}/>
                        <TitleActivity>
                            ЗАПЧАСТИ
                        </TitleActivity>
                    </ContainerActivity>
                    <Button to={'/parts#home'}>
                        ПОДРОБНЕЕ
                    </Button>
                </ImageBackground>
                <ImageBackground style={{backgroundImage: `url(${backgroundBox})`}}>
                    <ContainerActivity>
                        <img alt={'check'} src={require('./images/paycheque.png')}/>
                        <TitleActivity>
                            {'РАСТАРКА/\nЗАТАРКА'}

                        </TitleActivity>
                    </ContainerActivity>
                    <Button to={'/box#home'}>
                        ПОДРОБНЕЕ
                    </Button>
                </ImageBackground>
            </div>}
        </ContainerAboutUs>
    )
}

export default Activity
