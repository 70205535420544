import React, {FC} from 'react'
import {Anchor, PageTitle, Title} from "../../../styled";
import {ContainerAboutUs, TextTooltip} from "../AboutUs/styled";
import background from './images/map.png'
import {useImageSize} from "react-image-size";
import {data, dataTooltip} from "./data";
import {ContainerList, ListItem, Subtitle, TextItem} from "../Partners/styled";
import {ContainerMap, MarkerMap, TooltipMap, WrapperMap} from "./styled";
import {Tooltip} from "react-tooltip";

const Geography: FC = () => {
    const [dimensions] = useImageSize(background);

    return (
        <ContainerAboutUs>
            <Anchor id={'geography'}/>
            <Title>География</Title>
            <WrapperMap>
                <ContainerMap style={{
                    backgroundImage: `url(${background})`,
                    width: dimensions?.width,
                    height: dimensions?.height
                }}>
                    {dataTooltip.map((itemMap, index) => (
                        <Tooltip
                            style={{backgroundColor: '#FFFFFF', opacity: 1, borderRadius: 12, zIndex: 1111}}
                            key={itemMap.key}
                            closeEvents={{click: true}}
                            openEvents={{click: true}}
                            anchorSelect={`.${itemMap.key}`} place="top">
                            <TextTooltip style={{fontWeight:400}}>
                                {itemMap.value}
                            </TextTooltip>
                        </Tooltip>
                    ))}
                    <TooltipMap className="first" style={{marginTop: 198, marginLeft: 149}}><MarkerMap/></TooltipMap>
                    <TooltipMap className="second" style={{marginTop: 185, marginLeft: 335}}><MarkerMap/></TooltipMap>
                    <TooltipMap className="third" style={{marginTop: 198, marginLeft: 521}}><MarkerMap/></TooltipMap>
                </ContainerMap>
            </WrapperMap>
            <ContainerList>
                {data.map((itemMap, index) => (
                    <ListItem style={index % 2 === 1 ? {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    } : {alignItems: 'center'}} key={index}>
                        {itemMap.icon}
                        <TextItem>
                            <Subtitle>
                                {itemMap.title}
                            </Subtitle>
                        </TextItem>
                    </ListItem>
                ))}
            </ContainerList>
        </ContainerAboutUs>
    )
}

export default Geography
